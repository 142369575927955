import React, { useEffect, useRef } from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const ExitIntentV2 = ({
  onMobile,
  buttonLabel,
  heading,
  subHeading,
  btnLink,
  modalImg,
  surveyKey,
  compLogo,
}) => {
  const modalContainer = useRef(null)

  useEffect(() => {
    let timeout
    if (onMobile) {
      timeout = setTimeout(() => {
        const modalContainerRef = modalContainer.current
        if (modalContainerRef) {
          const finishSurvey = sessionStorage.getItem(surveyKey)
          if (!finishSurvey) {
            modalContainerRef.style.display = "block"
            sessionStorage.setItem(surveyKey, true)
          }
        }
      }, 20000)
    } else {
      timeout = setTimeout(() => {
        document.addEventListener("mouseleave", e => {
          const modalContainerRef = modalContainer.current
          if (!e.toElement && !e.relatedTarget && modalContainerRef) {
            const finishSurvey = sessionStorage.getItem(surveyKey)
            if (!finishSurvey) {
              modalContainerRef.style.display = "block"
              sessionStorage.setItem(surveyKey, true)
            }
          }
        })
      }, 10000)
    }

    return () => clearTimeout(timeout)
  }, [onMobile, surveyKey])

  const onModalClose = () => {
    const modalContainerRef = modalContainer.current
    modalContainerRef.style.display = "none"
  }

  useEffect(() => {
    window.onunload = function() {
      sessionStorage.removeItem(surveyKey)
    }
  })

  return (
    <>
      <div
        ref={modalContainer}
        className="exit-intent-popup vh-100 w-100 position-fixed"
        style={{
          display: "none",
          zIndex: 5,
          top: 0,
        }}
      >
        <div
          className="vh-100 w-100"
          style={{
            background: "rgba(0, 0, 0, 0.7)",
            top: 0,
            left: 0,
          }}
        >
          <div className="vh-100 position-relative">
            <div>
              <div className="container">
                <div className="row vh-100">
                  <div className="col-lg-8 align-self-center mx-auto">
                    <div className="py-3 bg-white rounded">
                      <div className="row">
                        <div className="col-lg-11 align-self-center mx-auto text-center p-0">
                          <div
                            id="clost-btn"
                            onClick={onModalClose}
                            aria-hidden="true"
                            className="close-btn exit-intent-close-btn"
                            style={{ top: "-35px" }}
                          >
                            x
                          </div>
                          <div
                            style={{
                              padding: "50px 60px",
                              background: `linear-gradient(90deg, rgba(138,73,161,0.8) 0%, rgba(138,73,161,0.8) 100%),url(${modalImg}) `,
                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              borderRadius: "5px",
                            }}
                          >
                            {" "}
                            {compLogo === "true" ? (
                              <StaticImage
                                placeholder="blurred"
                                className="img-fluid"
                                src="../../../static/assets/footer-logo.png"
                                alt="Quandary logo"
                                width={215}
                                layout="constrained"
                                loading="lazy"
                              />
                            ) : null}
                            <h2 className="font-weight-bold text-white mt-4">
                              {heading}
                            </h2>
                            <h5 className="mb-3 text-white">{subHeading}</h5>
                            {}
                            <div className={`btn-box mt-5 text-center`}>
                              <Link
                                to={btnLink}
                                id="modal-button"
                                className="text-white font-weight-bold text-uppercase"
                                onClick={onModalClose}
                                style={{ padding: "1rem 2rem" }}
                              >
                                {buttonLabel}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExitIntentV2
